export const hasBasicPlan = ({tenant}) => {
    return (tenant?.modules?.basic || false)
}

export const hasProfessionalPlan = ({tenant}) => {
    return tenant.status === 'trial_time' || (tenant?.modules?.professional || false)
}

export const hasCommercialReceiptModule = ({tenant}) => {
    return hasProfessionalPlan({tenant})
        && (tenant.settings?.tenant_nf?.nfce?.enabled && tenant?.settings?.tenant_nf?.nfce?.status === 'active')
}

export const hasCommercialInvoiceModule = ({tenant}) => {
    return hasBasicPlan({tenant}) || hasProfessionalPlan({tenant})
        && (tenant.settings?.tenant_nf?.nfse?.enabled && tenant?.settings?.tenant_nf?.nfse?.status === 'active')
}

export const hasFaceReconModuleEnable = ({tenant}) => {
    return (tenant.settings?.face_recon?.enabled || false)
}

export const hasTotemModuleEnabled = ({tenant}) => {
    return (tenant.settings?.totem?.enabled || false)
}

export const hasStonePaymentEnabled = ({tenant}) => {
    return hasProfessionalPlan({tenant})
        && (tenant.settings?.tenant_stone_payment?.enabled || false)
}

export const hasRankingCobrapaEnabled = ({tenant}) => {
    return tenant.settings?.tenant_ranking_cobrapa?.enabled || false
}
