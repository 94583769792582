<script>
import * as restfulService from '../../../../services/restfulService'

import {mapGetters} from 'vuex'
import bus from '@utils/bus'
import {confirmDialog} from '@utils/flash'

export default {
  name: 'queued-products',
  props: {
    date: {
      type: String,
      default: ''
    },
    hasModalOpened: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      drawer: false,
      queuedProducts: []
    }
  },
  computed: {
    ...mapGetters(['tenant', 'hasTotemModuleEnabled']),
    wsTotem() {
      const key = 'totemOrderUpdate'
      return {
        channel: `${key}.${this.tenant?.id || ''}`,
        listen: `.${key}`
      }
    },
    queuedProductCount() {
      return this.queuedProducts.length
    },
    hasQueuedProductCount() {
      return this.queuedProductCount > 0
    }
  },
  watch: {
    drawer(value) {
      if (value) {
        this.startWebsocket()
      }
    }
  },
  mounted() {
    this.startWebsocket()
    this.getProducts()
  },
  beforeDestroy() {
    this.closeWebsocket()
  },
  methods: {
    startWebsocket() {
      window.Echo.channel(this.wsTotem.channel).listen(this.wsTotem.listen, ({action}) => {
        if (action === 'store_order') {
          this.getProducts()
        }
      })
    },
    closeWebsocket() {
      window.Echo.leaveChannel(this.wsTotem.channel)
    },
    getProducts() {
      this.queuedProducts.splice(0)
      bus.$emit('show-loader')
      restfulService.post('order', 'queued-products', null, {date: this.date})
          .then((response) => {
            this.queuedProducts = [...response]
            if (!this.hasModalOpened) {
              this.drawer = this.hasQueuedProductCount > 0
            }
            bus.$emit('hide-loader')
          })
          .catch(e => {
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    async acceptProduct(orderProduct, index) {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente aceitar o pedido desse produto?',
        icon: 'warning',
      })
      if (confirm) {
        bus.$emit('show-loader')
        restfulService.put('order', `product/${orderProduct.order_product_id}/update-queued`, orderProduct.order_id, {})
            .then(() => {
              this.queuedProducts.splice(index, 1)
              if (!this.hasQueuedProductCount) {
                this.drawer = false
              }
              bus.$emit('hide-loader')
            })
            .catch(e => {
              bus.$emit('hide-loader')
              console.log(e)
            })
      }
    },
    async removeProduct(orderProduct, index) {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente remover esse produto?',
      })
      if (confirm) {
        bus.$emit('show-loader')
        restfulService.put('order', `product/${orderProduct.order_product_id}/delete`, orderProduct.order_id, {})
            .then(() => {
              this.queuedProducts.splice(index, 1)
              if (!this.hasQueuedProductCount) {
                this.drawer = false
              }
              bus.$emit('hide-loader')
            })
            .catch(e => {
              bus.$emit('hide-loader')
              console.log(e)
            })
      }
    }
  }
}
</script>

<template>
  <div class="d-inline-block ml-1" v-if="hasTotemModuleEnabled">
    <el-badge :value="queuedProductCount" :hidden="!hasQueuedProductCount">
      <el-button type="info" title="Produtos na fila"
                 :disabled="!hasQueuedProductCount"
                 class="ml-0"
                 @click="drawer = !drawer"
                 :size="$vuetify.breakpoint.xs ? 'mini' : 'small'">
        <i class="fas fa-stream"></i>
      </el-button>
    </el-badge>
    <el-drawer
        title="Pedidos via totem aguardando entrega"
        :visible="drawer"
        class="queued-products-drawer"
        direction="rtl">
      <div class="pl-4 pr-4 queued-products-list">
        <template v-for="(orderProduct, index) in queuedProducts">
          <div :key="index">
            <el-divider class="mt-2 mb-2"/>
            <div class="item">
              <div class="data">
                <div class="title">
                  {{ orderProduct.client }}
                  <span class="totem-name" v-if="orderProduct.created_by_user?.name">
                    | {{ orderProduct.created_by_user?.name }}
                  </span>
                </div>
                <div class="product">
                  ({{ orderProduct.quantity }}) {{ orderProduct.product }}
                </div>
                <div class="notes" v-if="orderProduct.notes">
                  Obs.: {{ orderProduct.notes }}
                </div>
              </div>
              <div class="actions">
                <el-button type="primary"
                           size="small"
                           title="Entregar Produto"
                           @click="acceptProduct(orderProduct, index)">
                  <i class="fas fa-walking"></i>
                </el-button>
                <el-button type="danger"
                           size="small"
                           class="ml-1"
                           title="Remove Produto"
                           @click="removeProduct(orderProduct, index)">
                  <i class="fas fa-trash"></i>
                </el-button>
              </div>
            </div>
          </div>
        </template>
      </div>
    </el-drawer>
  </div>
</template>
